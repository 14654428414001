import React from "react";
import axios from "axios";
import operator from "../../media/img/operator.png";

class AboutBox extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          fields: {},
          errors: {},
        };
      }
    
      handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        this.setState({ errors: errors });
        return formIsValid;
      }
    
      contactSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
            formData.append('phone', this.state.fields["phone"])
    
        if (this.handleValidation()) {
            axios.post('phone_entry.php', formData)
                      .then(function (response) {
                        //handle success
                        alert("Форма отправлена!")
                      })
                      .catch(function (response) {
                        //handle error
                        alert("Произошла ошибка при отправки формы");
                      });
        } else {
            alert("Проверьте, правильно ли заполнены все поля");
        }
    }
    
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
     }
    
    render(){
        const $baseClass = 'homepage-about'
        return(
            <div className={$baseClass}>
                <div className="responsive-wrapper rw-about-contact">
                    <div className="content-align">
                        <div className={`${$baseClass}-cardlist`}>
                            <div className={`${$baseClass}-cardlist-item`}>
                                <h1>К ВАМ ПРИЕДЕТ МАШИНИСТ-ОПЕРАТОР, В КВАЛИФИКАЦИИ КОТОРОГО МЫ ПОЛНОСТЬЮ УВЕРЕНЫ</h1>
                                <ul>
                                    <li>Аттестованные машинисты бурильно-крановых машин со стажем от 5 лет.</li>
                                    <li>Сотрудники имеют необходимые допуски для проведения строительных и ремонтных работ.</li>
                                    <li>Обязательное прохождение медосмотра перед выходом на смену.</li>
                                </ul>
                            </div>
                            <div className={`${$baseClass}-cardlist-item`}>
                                <h1>МЫ ИСПОЛЬЗУЕМ САМУЮ СОВРЕМЕННУЮ БУРОВУЮ СПЕЦТЕХНИКУ</h1>
                                <ul>
                                    <li>Полный перечень документов регистрации из Ростехнадзора на все машины.</li>
                                    <li>Проверяем исправность бурильно-крановых установок перед каждой сменой.</li>
                                    <li>Проводим регулярное техобслуживание спецтехники по регламенту заводов-изготовителей.</li>
                                </ul>
                            </div>
                            <div className={`${$baseClass}-cardlist-item`}>
                                <h1>САМАЯ ВЫГОДНАЯ ЦЕНА НА БУРЕНИЕ ЯМОБУРОМ В МОСКВЕ И ПОДМОСКОВЬЕ</h1>
                                <ul>
                                    <li>Снижаем цену при аренде нескольких машин или нескольких смен.</li>
                                    <li>Предоставляем скидку 10% в выходные и праздничные дни.</li>
                                    <li>Предлагаем специальные расценки на аренду ямобура для постоянных клиентов.</li>
                                </ul>
                            </div>
                            <div className={`${$baseClass}-cardlist-item`}>
                                <h1>ГАРАНТИИ КАЧЕСТВА УСЛУГ И ИСПОЛНЕНИЯ ОБЯЗАТЕЛЬСТВ ЗАКРЕПЛЕНЫ В ДОГОВОРЕ</h1>
                                <ul>
                                    <li>Сотрудничаем с частными лицами, коммерческими и бюджетными организациями (с НДС).</li>
                                    <li>Пришлём на объект аналогичную установку взамен сломавшейся через 2 часа</li>
                                    <li>Предоставим бухгатерские документы на следующий день после завершения работ.</li>
                                </ul>
                            </div>
                        </div>
                        <div className={`${$baseClass}-contact-us row`}>
                            <div className="col-7">
                                <div className={`${$baseClass}-contact-us-operator-info`}>
                                    <h1>Мария<br/>Барилова</h1>
                                    <p>Дежурный<br/>оператор</p>
                                    <a href="mailto:barilova_mariya@mail.ru">barilova_mariya@mail.ru</a>
                                </div>
                                <div></div>
                                <img src={operator} alt="оператор"/>
                            </div>
                            <div className="col-5">
                                <div className={`${$baseClass}-contact-us-operator-contact`}>
                                    <h1>Если Вы хотите узнать наши расценки на бурение ямобуром – позвоните мне!</h1>
                                    <p>Я помогу подобрать оптимальную технику для решения Вашей задачи, моментально рассчитаю точную стоимость работ на любом объекте Москвы и Московской области, расскажу, чем выгодно отличается аренда ямобура в нашей компании и отвечу на Ваши вопросы. Звоните, у нас самая выгодная цена за час!</p>
                                    <a href="tel:89091638412">+7(909)163-84-12</a>
                                    <form onSubmit={this.contactSubmit.bind(this)}>
                                        <input
                                            refs="phone"
                                            onChange={this.handleChange.bind(this, "phone")}
                                            value={this.state.fields["phone"]}
                                            name="phone"
                                            type="text"
                                            placeholder="Или оставьте свой номер"
                                        />
                                        <button type="submit" className="button-blue">Отправить</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="responsive-wrapper rw-about-info">
                    <div className="content-align">
                        <div className={`${$baseClass}-contact-us-info`}>
                            <div className={`${$baseClass}-contact-us-info-work-expirience`}>
                                <div className={`${$baseClass}-contact-us-info-work-expirience-item`}>
                                    <h1>10</h1>
                                    <p>ЯМОБУРОВ<br/>В ПАРКЕ</p>
                                </div>
                                <div className={`${$baseClass}-contact-us-info-work-expirience-item`}>
                                    <h1>5 лет</h1>
                                    <p>МАКСИМАЛЬНЫЙ<br/>ВОЗРАСТ<br/>ЯМОБУРА</p>
                                </div>
                                <div className={`${$baseClass}-contact-us-info-work-expirience-item`}>
                                    <h1>15 лет</h1>
                                    <p>ОПЫТ<br/>БУРЕНИЯ<br/>СКВАЖИН</p>
                                </div>
                                <div className={`${$baseClass}-contact-us-info-work-expirience-item`}>
                                    <h1>1 час</h1>
                                    <p>ВРЕМЯ<br/>ПОДАЧИ<br/>В МОСКВЕ</p>
                                </div>
                                <div className={`${$baseClass}-contact-us-info-work-expirience-item`}>
                                    <h1>24/7</h1>
                                    <p>РАБОТАЕМ<br/>КРУГЛОСУТОЧНО</p>
                                </div>
                                <div className={`${$baseClass}-contact-us-info-work-expirience-item`}>
                                    <h1>700+</h1>
                                    <p>ВЫПОЛНЕННЫХ<br/>ЗАКАЗОВ<br/>ЗА ГОД</p>
                                </div>
                            </div>
                            <div className={`${$baseClass}-contact-us-info-services`} id="services">
                                <h1>Наши услуги</h1>
                                <div className="row">
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <h2>Монтаж винтовых свай</h2>
                                        <ul>
                                            <li>Под фундамент</li>
                                            <li>Под забор</li>
                                            <li>Под причалы, пирсы, мосты</li>
                                            <li>Под баню, пристройку, беседку, крыльцо</li>
                                            <li>Установка виновых опор</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <h2>Бурение</h2>
                                        <ul>
                                            <li>Грунта</li>
                                            <li>800 диаметра</li>
                                            <li>Колодцев</li>
                                            <li>Под забор</li>
                                            <li>Под фундамент</li>
                                            <li>Под септик</li>
                                            <li>Шнековое бурение</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <h2>Аренда ямобура</h2>
                                        <ul>
                                            <li>Ямобур Aichi D502 ISUZU ELF</li>
                                            <li>Ямобур Aichi D502 KAMAZ</li>
                                            <li>Ямобур Aichi D706 MITSUBISHI FUSO</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <h2>Бурение и изготовление свай</h2>
                                        <ul>
                                            <li>Устройство буронабивных свай</li>
                                            <li>Монтаж винтовых свай</li>
                                            <li>ТИСЭ</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <h2>Монтаж столбов, опор, ЛЭП</h2>
                                        <ul>
                                            <li>Установка столбов освещения</li>
                                            <li>Установка  опор линий электропередач</li>
                                            <li>Установка дорожных знаков</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12">
                                        <h2>Шпунтовое ограждение котлована</h2>
                                        <ul>
                                            <li>Монтаж шпунта из металлических труб</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="responsive-wrapper rw-about-company">
                    <div className="content-align" id="about">
                        <div className={`${$baseClass}-company`}>
                            <div className={`${$baseClass}-company-info`}>
                                <h1>Информация о компании</h1>
                                <p>Наша компания на протяжении 7 лет работает на строительном рынке Москвы и Московской области и за это время приобрела хорошую репутацию. Основные задачи нашей компании — это оперативность, качество выполняемой работы и профессионализм наших сотрудников. В компании «СпецМашТех» используется современное оборудование, котрое позволяет нам выполнять буровые работы различной степени сложности, в самых разнообразных условиях.</p>
                            </div>
                            <div className={`${$baseClass}-company-contacts row`} id="contacts">
                                <div className="col-6 col-lg-12">
                                    <div>
                                        <h2>Юридический адрес</h2>
                                        <h3>Московская область,<br/>Мытищинский район,<br/>с.Виноградово ул.Виноградная 21</h3>
                                    </div>
                                    <div>
                                        <h2>Контакты</h2>
                                        <div className={`${$baseClass}-company-contacts-wrapper`}>
                                            <div>
                                                <p>Телефон</p>
                                                <a href="tel:89091638412">+7(909)163-84-12</a>
                                            </div>
                                            <div>
                                                <p>E-mail</p>
                                                <a href="mailto:barilova_mariya@mail.ru">barilova_mariya@mail.ru</a>
                                            </div>
                                            <div className="wrapper-exclusive">
                                                <p>Мессенджеры</p>
                                                <div>
                                                    <span><a href="https://api.whatsapp.com/send?phone=79091638412">WhatsApp</a></span>
                                                    <span className="separator"> | </span>
                                                    <span><a href="https://t.me/+79091638412">Telegram</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-12 requisites">
                                    <h2>Реквизиты</h2>
                                    <div className={`${$baseClass}-company-contacts-wrapper`}>
                                        <div>
                                            <p>Название организации</p>
                                            <h2>ООО “Спецмаштех”</h2>
                                        </div>
                                        <div>
                                            <p>Корреспондентский счёт</p>
                                            <h2>30101810145250000974</h2>
                                        </div>
                                        <div>
                                            <p>Расчётный счёт</p>
                                            <h2>40702810010000964954</h2>
                                        </div>
                                        <div>
                                            <p>БИК</p>
                                            <h2>044525974</h2>
                                        </div>
                                        <div>
                                            <p>ИНН</p>
                                            <h2>9721151820</h2>
                                        </div>
                                        <div>
                                            <p>ОГРН</p>
                                            <h2>1217700593660</h2>
                                        </div>
                                        <div>
                                            <p>КПП</p>
                                            <h2>772101001</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutBox