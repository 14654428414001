import React from "react";
import axios from "axios";

class Form extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        fields: {},
        errors: {},
      };
    }

    componentDidMount(){
      this.setState({fields: {...this.state.fields, "drill": this.props.drill, "diameter": "200", "depth": "3"}})
    }
  
    handleValidation() {
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
  
      //Name
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "Заполните поле (от 2 до 18 символов)";
      }
  
      if (typeof fields["name"] !== "undefined") {
        if (!fields["name"].match(/^[А-Яа-яЁё]+$/)) {
          formIsValid = false;
          errors["name"] = "Только Кирилица";
        }
      }
  
      //Email
      if (!fields["phone"]) {
        formIsValid = false;
        errors["phone"] = "Заполните поле";
      }
  
      if (typeof fields["email"] !== "undefined") {
        let lastAtPos = fields["email"].lastIndexOf("@");
        let lastDotPos = fields["email"].lastIndexOf(".");
  
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["email"].indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            fields["email"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["email"] = "Неправильный адрес электронной почты";
        }
      }
  
      this.setState({ errors: errors });
      return formIsValid;
    }
  
    contactSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
        formData.append('name', this.state.fields["name"])
        formData.append('phone', this.state.fields["phone"])
        formData.append('depth', this.state.fields["depth"])
        formData.append('diameter', this.state.fields["diameter"])
        formData.append('choice', this.state.fields["choice"])
        formData.append('drill', this.state.fields["drill"])
  
      if (this.handleValidation()) {
        axios.post('entry.php', formData)
                    .then(function (response) {
                      //handle success
                      alert("Форма отправлена!")
                    })
                    .catch(function (response) {
                      //handle error
                      alert("Произошла ошибка при отправки формы");
                    });
      } else {
        alert("Проверьте, правильно ли заполнены все поля");
      }
    }
  
    handleChange(field, e) {
      let fields = this.state.fields;
      fields[field] = e.target.value;
      this.setState({ fields });
    }
    
    render(){
        const $baseClass = "homepage";
        return(
            <form name="contactform" id={this.props.id} onSubmit={this.contactSubmit.bind(this)} className={`${$baseClass}-header-form`}>
                <h1>Форма быстрой заявки</h1>
                <div className={`${$baseClass}-header-form-wrapper`}>
                <input
                  refs="drill"
                  onChange={this.handleChange.bind(this, "drill")}
                  value={this.props.drill}
                  type="hidden"
                  name="drill"
                />
                <label>Как к вам обращаться?
                    <input
                        ref="name"
                        onChange={this.handleChange.bind(this, "name")}
                        value={this.state.fields["name"]}
                        type="text"
                        name="name"
                        placeholder="Введите ваше имя"
                        required
                    />
                    <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
                </label>
                <label>Укажите свой номер телефона
                    <input 
                        refs="phone"
                        onChange={this.handleChange.bind(this, "phone")}
                        value={this.state.fields["phone"]}
                        type="text"
                        name="phone"
                        max-length="10"
                        pattern="^{11-16}$"
                        placeholder="+7(999)999-99-99"
                        required
                    />
                    <span style={{ color: "red" }}>{this.state.errors["phone"]}</span>
                </label>
                <label>Диаметр бурения
                    <select 
                        refs="diameter"
                        onChange={this.handleChange.bind(this, "diameter")}
                        value={this.state.fields["diameter"]}
                        name="diameter"
                        required
                        initial-value="200"
                    >
                        <option data-value="200">200 мм</option>
                        <option data-value="250">250 мм</option>
                        <option data-value="300">300 мм</option>
                        <option data-value="350">350 мм</option>
                        <option data-value="400">400 мм</option>
                        <option data-value="450">450 мм</option>
                        <option data-value="500">500 мм</option>
                        <option data-value="600">600 мм</option>
                        <option data-value="700">700 мм</option>
                        <option data-value="800">800 мм</option>
                        <option data-value="900">900 мм</option>
                        <option data-value="1000">1000 мм</option>
                        <option data-value="1100">1100 мм</option>
                        <option data-value="1200">1200 мм</option>
                    </select>
                    <div className="select-arrow"></div>
                </label>
                <label>Глубина бурения
                <select 
                        refs="depth"
                        onChange={this.handleChange.bind(this, "depth")}
                        value={this.state.fields["depth"]}
                        name="depth"
                        required
                        initial-value="3"
                    >
                        <option data-value="3">3 метра</option>
                        <option data-value="5">5 метров</option>
                        <option data-value="10">10 метров</option>
                        <option data-value="15">15 метров</option>
                    </select>
                    <div className="select-arrow"></div>
                </label>
                <label>Какие работы предстоит выполнить?
                    <input
                        refs="choice"
                        onChange={this.handleChange.bind(this, "choice")}
                        value={this.state.fields["choice"]}
                        type="text"
                        max-length="30"
                        name="choice"
                        placeholder="К примеру: установка шпунта"
                        required
                    />
                </label>
                <div className={`${$baseClass}-header-form-wrapper-inner`}>
                    <div></div>
                    <button type="submit" className="button-blue">Отправить</button>
                </div>
            </div>
        </form>
        )
    }
}

export default Form;