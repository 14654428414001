import React from "react";
import products from "./products";
import Form from "./Form";
import navcross from "../../media/svg/nav-cross.svg";

class ListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayDesc: Boolean,
            disCl: String,
            opened: Boolean
        };
    }
    
    componentDidMount(){
        this.setState({
            disCl: "Открыть описание",
            displayDesc: false,
            opened: false
        })
    }

    render(){
        const $baseClass = 'homepage-product-list-item';
        return(
            <div className={`${$baseClass} row align-items-start`}>
                <div className={`${$baseClass}-form-wrapper`} id={this.state.opened? "opened" : null}>
                    <div className={`${$baseClass}-form-wrapper-cross`} onClick={() => {
                        this.setState({opened: !this.state.opened})
                    }}/>
                    <Form drill={this.props.name} id={`${$baseClass}-form`} />
                </div>
                <div className="col-9">
                    <div className={`${$baseClass}-card`}>
                        <div className="row">
                            <div className={`${$baseClass}-card-images col-6`}>
                                <div id={`${$baseClass}-card-images-carousel-${this.props.id}`} className={`${$baseClass}-card-images-carousel carousel slide`} data-bs-pause="hover" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item img-responsive ratio ratio-4x3 active">
                                            <img src={this.props.img1} alt={this.props.name}/>
                                        </div>
                                        <div className="carousel-item img-responsive ratio ratio-4x3" style={{background: this.props.img2}}>
                                            <img src={this.props.img2} alt={this.props.name}/>
                                        </div>
                                        <div className="carousel-item img-responsive ratio ratio-4x3">
                                            <img src={this.props.img3} alt={this.props.name}/>
                                        </div>
                                    </div>
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target={`#${$baseClass}-card-images-carousel-${this.props.id}`} data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"><img src={this.props.img1} alt={this.props.name}/></button>
                                        <button type="button" data-bs-target={`#${$baseClass}-card-images-carousel-${this.props.id}`} data-bs-slide-to="1" aria-label="Slide 2"><img src={this.props.img2} alt={this.props.name}/></button>
                                        <button type="button" data-bs-target={`#${$baseClass}-card-images-carousel-${this.props.id}`} data-bs-slide-to="2" aria-label="Slide 3"><img src={this.props.img3} alt={this.props.name}/></button>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target={`#${$baseClass}-card-images-carousel-${this.props.id}`} data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target={`#${$baseClass}-card-images-carousel-${this.props.id}`} data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                            <div className={`${$baseClass}-card-specs col-6`}>
                                <h1>{this.props.name}</h1>
                                <div className={`${$baseClass}-card-specs-wrapper`}>
                                    <ul className={`${$baseClass}-card-specs-table`}>
                                        <li><span>Диаметр бурения</span></li>
                                        <li><span>Глубина бурения</span></li>
                                        <li><span>Рабочий радиус</span></li>
                                        <li><span>Базовое шасси</span></li>
                                        <li><span>Буровая установка</span></li>
                                        <li><span>Угол поворота платформы</span></li>
                                    </ul>
                                    <ul className={`${$baseClass}-card-specs-table`}>
                                        <li><span>{this.props.diameter}</span></li>
                                        <li><span>{this.props.depth}</span></li>
                                        <li><span>{this.props.radius}</span></li>
                                        <li><span>{this.props.base}</span></li>
                                        <li><span>{this.props.drillType}</span></li>
                                        <li><span>{this.props.angle}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className={`${$baseClass}-card-description col-12${this.state.displayDesc? ' toggled' : null}`}>{this.props.desc}</p>
                        <div className="toggle-btn-wrapper"><span onClick={() => this.setState({displayDesc: !this.state.displayDesc}, () => { this.state.displayDesc? this.setState({disCl: "Скрыть описание"}) : this.setState({disCl: "Открыть описание"}) })} className={`${$baseClass}-card-description-toggle-btn`}>{this.state.disCl}</span></div>
                    </div>
                </div>
                <div className="col-3">
                    <div className={`${$baseClass}-price`}>
                        <h1>{this.props.price}₽<span>/ час</span></h1>
                        <hr />
                        <div className={`${$baseClass}-price-wrapper`}>
                            <ul className={`${$baseClass}-price-table`}>
                                <li>Стоимость смены 7+1</li>
                                <li>Прогон за МКАД</li>
                                <li>1 час переработки</li>
                            </ul>
                            <ul className={`${$baseClass}-price-table`}>
                                <li>{this.props.price2}₽</li>
                                <li>{this.props.price3}₽ / км</li>
                                <li>{this.props.price4}₽</li>
                            </ul>
                        </div>
                        <div className={`${$baseClass}-price-button-wrapper`}>
                            <button onClick={() => {
                                this.setState({opened: !this.state.opened})
                            }} className={`${$baseClass}-price-button button-blue`}>Арендовать <span>за {this.props.price}₽ / час</span></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            products: products
        }
    }

    componentDidMount(){
        
    }

    render(){
        const $baseClass = 'homepage-product-list';
        return(
            <div className={$baseClass} id="drills">
                <div className="responsive-wrapper rw-productlist">
                    <div className="content-align">
                        <h1 className={`${$baseClass}-title`}>Доступная к аренде техника</h1>
                        {this.state.products.map((product, index) => (
                            <ListItem
                                key={index}
                                id={index}
                                img1={product.img1}
                                img2={product.img2}
                                img3={product.img3}
                                name={product.name}
                                diameter={product.diameter}
                                depth={product.depth}
                                radius={product.radius}
                                base={product.base}
                                drillType={product.drillType}
                                angle={product.angle}
                                price={product.price}
                                price2={product.price2}
                                price3={product.price3}
                                price4={product.price4}
                                desc={product.desc}
                            />
                        ))}
                        <hr/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductList;