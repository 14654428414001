import React from "react";
import dropdown from "../media/svg/arrow-drop.svg";
import navburger from "../media/svg/nav-burger.svg";
import navcross from "../media/svg/nav-cross.svg";
import logoLight from "../media/svg/logo-big-light.svg";
import logoDark from "../media/svg/logo-big-dark.svg";

class Navbar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tgl: false 
        }
    }
    
    componentDidMount(){
        this.setState({
            tgl: false
        })
    }

    render() {
        const $baseClass = "navbar";
        return (
            <div className={`${$baseClass}-top-static`}>
                <div className={`${$baseClass}-collapsible ${this.state.tgl? "toggled" : "out"}`}>
                    <div><div></div><div className={`${$baseClass}-collapsible-navcross`} onClick={() => {
                        this.setState({tgl: !this.state.tgl})
                    }}/></div>
                    <div className={`${$baseClass}-collapsible-wrapper`}>
                        <a onClick={(e) => {
                            this.setState({tgl: !this.state.tgl})
                        }} title="Полный список ямобуров" href='#drills'>Ямобуры</a>
                        <a onClick={(e) => {
                            this.setState({tgl: !this.state.tgl})
                        }} title="Наши услуги" href='#services'>Услуги</a>
                        <a onClick={(e) => {
                            this.setState({tgl: !this.state.tgl})
                        }} href="#about">О компании</a>
                        <a onClick={(e) => {
                            this.setState({tgl: !this.state.tgl})
                        }} title="Связаться с нами" href="#contacts">Контакты</a>
                        <img src={logoDark} alt="СМТ"/>
                    </div>
                </div>
                <div className={`${$baseClass}-wrapper`}>
                    <div className={`${$baseClass}-logo`}>
                        <img src={logoLight} alt="СМТ"/>
                    </div>
                    <div className={`${$baseClass}-links`}>
                        <div className="link-box"><a className={`${$baseClass}-link`} title="Полный писок ямобуров" href='#drills'>Ямобуры</a><img className="dropdown-arrow" src={dropdown} alt="dropdown arrow"/></div>
                        <div className="link-box"><a className={`${$baseClass}-link`} title="Наши услуги" href='#services'>Услуги</a><img className="dropdown-arrow" src={dropdown} alt="dropdown arrow"/></div>
                        <a className={`${$baseClass}-link base-link`} href="#about" >О компании</a>
                        <a className={`${$baseClass}-link base-link`} title="Связаться с нами" href="#contacts" >Контакты</a>
                    </div>
                    <div className={`${$baseClass}-info`}>
                        <a href="tel:89091638412" title="Горячая линия">+7(909)163-84-12</a>
                        <p>Ростехнадзор. Пропуск в центр. <br/> Работаем 24/7 без выходных!</p>
                        <a className={`${$baseClass}-info-button ${this.props.btnClr}`} title="Связаться с нами" href="#form" >Оставить заявку</a>
                    </div>
                    <div onClick={() => {
                        this.setState({tgl: !this.state.tgl})
                    }} className={`${$baseClass}-burger`}>
                        <img src={navburger} />
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Navbar;