import img1 from '../../media/img/prods/drill1.png'
import img2 from '../../media/img/prods/drill2.png'
import img3 from '../../media/img/prods/drill3.png'
import img4 from '../../media/img/prods/mitsubishi-fuso1.jpg'
import img5 from '../../media/img/prods/mitsubishi-fuso2.jpg'
import img6 from '../../media/img/prods/mitsubishi-fuso3.jpg'
import img7 from '../../media/img/prods/kamaz1.jpg'
import img8 from '../../media/img/prods/kamaz2.jpg'
import img9 from '../../media/img/prods/kamaz3.jpg'

const products = [
    {
        name: 'Ямобур Aichi D502 ISUZU ELF',
        diameter: '200 - 600 мм',
        depth: '10 м',
        radius: '12 м',
        base: 'ISUZU ELF',
        drillType: 'Aichi D502',
        angle: '360°',
        price: '2 250',
        price2: '18 000',
        price3: '40',
        price4: '2 500',
        img1: img2,
        img2: img1,
        img3: img3,
        desc: 'Современная бурильная машина, которая активно применяется для бурения углублений под опоры и столбы, а также телефонные линии связи. Данная установка имеет специальную платформу, которую можно установить на грузовые авто любого бренда производства. Модель идеально подходит для бурения и обустройства скважин любой глубины и предназначения. Нашим сервисом предлагаются модели с различным типом шасси и дополнительным оборудованием, что подойдет для самых сложных работ. Диаметр бурения 200 до 800 мм. Закрутка свай диаметром 89, 108, 133, 159, 219 мм. Ямобур Aichi предоставляется в аренду нашей компанией по выгодной и демократичной стоимости, что позволит сохранить бюджет, исключить покупку новой установки и получить нужное оборудование на необходимое рабочее время. Ямобур Aichi D502, так же хорошо подойдет для бурения под столбы.'
    },
    {
        name: 'Ямобур Aichi D706 MITSUBISHI FUSO',
        diameter: '360 - 800 мм',
        depth: '10 м',
        radius: '12 м',
        base: 'MITSUBISHI FUSO',
        drillType: 'Aichi D706',
        angle: '360°',
        price: '2 500',
        price2: '20 000',
        price3: '40',
        price4: '2800',
        img1: img6,
        img2: img5,
        img3: img4,
        desc: 'Современная бурильная машина, которая активно применяется для бурения углублений под опоры и столбы, а также телефонные линии связи. Данная установка имеет специальную платформу, которую можно установить на грузовые авто любого бренда производства. Модель идеально подходит для бурения и обустройства скважин любой глубины и предназначения. Нашим сервисом предлагаются модели с различным типом шасси и дополнительным оборудованием, что подойдет для самых сложных работ. Диаметр бурения 360 до 800 мм. Закрутка свай диаметром 89, 108, 133, 159, 219 мм. Ямобур Aichi предоставляется в аренду нашей компанией по выгодной и демократичной стоимости, что позволит сохранить бюджет, исключить покупку новой установки и получить нужное оборудование на необходимое рабочее время. Ямобур Aichi D706, так же хорошо подойдет для бурения под столбы.'
    },
    {
        name: 'Ямобур Aichi D502 KAMAZ',
        diameter: '200 - 600 мм',
        depth: '10 м',
        radius: '12 м',
        base: 'KAMAZ',
        drillType: 'Aichi D502',
        angle: '360°',
        price: '4 375',
        price2: '35 000',
        price3: '100',
        price4: '5000',
        img1: img8,
        img2: img7,
        img3: img9,
        desc: 'Современная бурильная машина, которая активно применяется для бурения углублений под опоры и столбы, а также телефонные линии связи. Данная установка имеет специальную платформу, которую можно установить на грузовые авто любого бренда производства. Модель идеально подходит для бурения и обустройства скважин любой глубины и предназначения. Нашим сервисом предлагаются модели с различным типом шасси и дополнительным оборудованием, что подойдет для самых сложных работ. Диаметр бурения 200 до 800 мм. Закрутка свай диаметром 89, 108, 133, 159, 219 мм. Ямобур Aichi предоставляется в аренду нашей компанией по выгодной и демократичной стоимости, что позволит сохранить бюджет, исключить покупку новой установки и получить нужное оборудование на необходимое рабочее время. Ямобур Aichi D502, так же хорошо подойдет для бурения под столбы.'
    }
]

export default products