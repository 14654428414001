import React from "react";

class Footer extends React.Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
    
    render(){
        const $baseClass = 'footer'
        return(
            <footer className={`${$baseClass} row`}>
                <div className="responsive-wrapper rw-footer">
                    <div className="content-align row">
                        <div className="col-7">
                            <h1>СМТ</h1>
                            <p>УМНЫЙ ПОДХОД К БУРЕНИЮ</p>
                        </div>
                        <div className="col-5">
                            <h2>Контакты</h2>
                            <div className={`${$baseClass}-wrapper`}>
                                <div>
                                    <p>Телефон</p>
                                    <a href="tel:89091638412">+7(909)163-84-12</a>
                                </div>
                                <div>
                                    <p>E-mail</p>
                                    <a href="mailto:barilova_mariya@mail.ru">barilova_mariya@mail.ru</a>
                                </div>
                                <div>
                                    <p>Мессенджеры</p>
                                    <div>
                                        <span><a href="https://api.whatsapp.com/send?phone=79091638412">WhatsApp</a></span>
                                        <span className="separator"> | </span>
                                        <span><a href="https://t.me/+79091638412">Telegram</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${$baseClass}-copyright`}>
                            <div>
                                <span>
                                    Privacy Policy
                                </span>
                                <span>
                                    © Copyright 2003-2022 | СМТ. All Rights Reserved
                                </span>
                                <span>
                                    Made with love by <a href="https://chepirka.ru">Chepirka</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </footer>
        )
    }
}

export default Footer;