import './styles/home.sass';
import React from 'react';
import Navbar from './components/Navbar';
import ProductList from './components/homepage/ProductList';
import AboutBox from './components/homepage/AboutBox';
import Footer from './components/Footer';
import FormHeader from './components/homepage/FormHeader';

function Home() {
  const $baseClass = "homepage";
  return (
    <div className={`${$baseClass}`}>
      <header className={`${$baseClass}-header`}>
        <div className="responsive-wrapper rw-header">
          <div className="content-align">
            <Navbar 
              btnClr='button-blue'
            />
            <div className={`${$baseClass}-form-wrapper`}>
              <h1 className="text-center">АРЕНДА БУРИЛЬНО-КРАНОВЫХ МАШИН</h1>
              <h3 className="text-center">В МОСКВЕ И МОСКОВСКОЙ ОБЛАСТИ</h3>
            </div>
            <div className={`${$baseClass}-header-form-wrapper-outer`}>
              <FormHeader drill="не выбрана" id={"form"}/>
            </div>
            <div className="transparent" />
          </div>
        </div>
      </header>
      <ProductList />
      <AboutBox />
      <Footer />
    </div>
  );
}

export default Home;
